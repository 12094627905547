import { PaymentControllerService } from '@/__generated__/CommonApi';
import Agreement from '@/components/Agreement';
import Button from '@/components/Button';
import { CartItem } from '@/components/CartList';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup, { FormItem } from '@/components/Form/FormGroup';
import FormTextTip from '@/components/Form/FormTextTip';
import LabelText from '@/components/Form/LabelText';
import GiftList from '@/components/GiftList';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import HR from '@/components/HR';
import AddressButton from '@/components/Input/AddressButton';
import InputCheckbox, {
  CheckboxInput,
  CheckboxLabel,
  CheckboxObject,
  CheckboxText,
} from '@/components/Input/InputCheckbox';
import InputDate from '@/components/Input/InputDate';
import InputNumber from '@/components/Input/InputNumber';
import InputNumberMasking from '@/components/Input/InputNumberMasking';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import InputTextarea from '@/components/Input/InputTextarea';
import PostTooltip from '@/components/PostTooltip';
import Select from '@/components/Select';
import { NotiBox, NotiItem, NotiList } from '@/components/Text/TextNoti';
import InicisMobileOnceForm, {
  InicisMobileOnceRef,
} from '@/components/ThirdParty/InicisMobileOnceForm';
import InicisPcOnceForm, {
  InicisPcOnceRef,
} from '@/components/ThirdParty/InicisPcOnceForm';
import MobiliansOnceForm, {
  MobiliansOnceRef,
} from '@/components/ThirdParty/MobiliansOnceForm';
import NaverPayForm, {
  NaverPayRef,
} from '@/components/ThirdParty/NaverPayForm';
import NiceAuthenticationForm from '@/components/ThirdParty/NiceAuthenticationForm';
import SirenNameCheck, {
  NameCheckMemberInfo,
} from '@/components/ThirdParty/SirenNameCheck';
import { Tit, H4 } from '@/components/Titles';
import { StepBody, StepHead, ToggleBox } from '@/components/ToggleContainer';
import ToggleDiv from '@/components/ToggleDiv';
import {
  SupportPrivacyAgreeContent,
  SupportAgreeContent,
} from '@/data/agreements';
import { breakpoint, Breakpoints } from '@/helpers/BreakpointHelper';
import { isMobileDevice } from '@/helpers/BrowserHelper';
import GtmHelper from '@/helpers/GtmHelper';
import {
  INICIS_PAY_METHOD_MOBILE_MAP,
  INICIS_PAY_METHOD_PC_MAP,
  PAYMENT_TYPE_NAMES,
  PAYTYPE_ONCE_BANKING,
  PAYTYPE_ONCE_CARD,
  PAYTYPE_ONCE_NAVERPAY,
  // PAYTYPE_ONCE_PHONE,
} from '@/helpers/PaymentHelper';
import { getFullBirthday } from '@/helpers/StringHelper';
import {
  SUPPORT_CATEGORY_ONCE_IG,
  SUPPORT_CATEGORY_ONCE_MEMORY,
  SUPPORT_TERM_ONCE,
  ENUM_SUPPORTER_TYPE,
  TYPE_MEMBER_TYPES,
  SUPPORT_TERM_REGULAR,
  useFindSupportCategory,
  MEMBER_TYPES,
  MEMBER_TYPE_INDIVIDUAL,
} from '@/helpers/SupportHelper';
import { emailRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  FormContainer,
  FormButton,
  RightCol,
} from '@/page-blocks/auth/AuthCommon';
import { DonateFormItem, LabelFlex } from '@/page-blocks/donate/DonateCommon';
import { SupportStep1Data } from '@/page-blocks/donate/DonateStep1';
import SessionStorage from '@/services/SessionStorage';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import dayjs from 'dayjs';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import {
  getDonateEncKey,
  resetDonateEncKey,
} from '@/components/ThirdParty/SirenNameCheck';

const Section = styled.section`
  padding-top: 96px;

  ${breakpoint(`tablet`)} {
    padding-top: 70px;
  }

  &:last-child {
    padding-bottom: 190px;
  }

  .btn-wrap {
    max-width: 544px;
    margin: 0 auto;
    margin-top: 120px;
  }

  .payment-container {
    padding-bottom: 120px;
  }

  .agreement-container {
    max-width: 544px;
  }

  ${StepHead} {
    .c1 {
      color: #1cabe2;
      font-weight: 400;
    }
  }

  ${ToggleBox} {
    ${Tit} {
      color: #2d2926;
    }
    form {
      display: block;
    }

    ${NotiBox} {
      margin-bottom: 24px;
    }
  }
`;

const GiftFormContainer = styled(FormContainer)`
  .form-hide {
    display: none;
  }

  .FormItem {
    border-bottom: 0;
  }

  .FormGroup {
    &.last {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  .sec3 {
    ${FormItem} {
      border-bottom: none;
    }
  }
`;

const TotalAmount = styled.dl`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  padding: 32px 0;
  border-top: 1px solid #2d2926;
  border-bottom: 1px solid #2d2926;
  margin-top: 45px;

  dt {
    font-size: 20px;
    ${breakpoint(`mobile`)} {
      font-size: 16px;
    }
  }
  dd {
    font-size: 32px;
    color: #1cabe2;
    line-height: 1.6;
    letter-spacing: -1.2px;
    ${breakpoint(`mobile`)} {
      font-size: 20px;
    }
  }
`;

const FormatJumin = styled.div`
  width: auto;
  input {
    border: 0px;
    :focus {
      border: 0px;
    }
  }

  border: 1px solid #e5e6e8;
  :focus-within {
    border: 1px solid #2589c9;
  }

  .hypen {
    margin-left: 24px;
    ${breakpoint(425)} {
      margin-left: 14px;
    }
    ${breakpoint(375)} {
      margin-left: 10px;
    }
    ${breakpoint(363)} {
      margin-left: 1px;
    }
  }

  input:nth-child(1) {
    ${breakpoint(400)} {
      padding-right: 0px;
      width: 105px;
    }
    ${breakpoint(337)} {
      padding-left: 1px;
      width: 88px;
    }
  }

  input:nth-child(3) {
    ${breakpoint(400)} {
      padding-right: 0px;
      width: 105px;
    }
    ${breakpoint(375)} {
      padding-left: 10px;
    }
    ${breakpoint(363)} {
      padding-left: 1px;
      width: 88px;
    }
  }

  .juminNumber {
    display: inline;
    width: 150px;
    padding: 0 16px;
    ${breakpoint(`mobile`)} {
      width: 120px;
    }
  }
`;

interface GiftFormLocationState {
  items: CartItem[];
}

export interface GiftFormStep2Data {
  supporterType: ENUM_SUPPORTER_TYPE;
  memberTypeCode: keyof TYPE_MEMBER_TYPES;
  memberName: string;
  bankingOwnerRelation: string;
  juminNumber: string;
  juminNumber1: string;
  juminNumber2: string;
  gender: string;
  juminEnc: string;
  // 사업자등록번호
  businessNumber: string;
  // 법정대리인 이름
  relationName: string;
  // 법정대리인 관계
  relationType: string;
  relationTelNumber: string;
  agreeReceipt: boolean;
  mobilePhoneNumber: string;
  email: string;
  birthday: string;
  // 마케팅정보 수신여부
  mobileAgree: boolean;
  emailAgree: boolean;
  postAgree: boolean;
  zipCode: string;
  address: string;
  addressDetail: string;
  // 기념일 관련
  anniType: string;
  anniDate: string;
  story: string;
  // 법인/단체 담당자 관련
  contact: string;
  managerName: string;
  managerEmail: string;
}

export interface GiftFormStep3Data {
  paymentType: keyof typeof PAYMENT_TYPE_NAMES;
  // 이용약관
  policyAgree: boolean;
}

type GiftFormData = SupportStep1Data & GiftFormStep2Data & GiftFormStep3Data;

type SubmitParams = Parameters<
  typeof PaymentControllerService.paymentInfoSetUsingPost
>[0];

// 고정데이터 (생구선 전용)
const FixedData = {
  supportTerm: SUPPORT_TERM_ONCE,
  supportCategory: SUPPORT_CATEGORY_ONCE_IG,
  // supporterType: ENUM_SUPPORTER_TYPE.person,
  // memberTypeCode: `IN`,
};

const GifFormPage: FC<
  PageProps<any, any, GiftFormLocationState | null>
> = observer(({ location }) => {
  const items = location.state?.items;

  const inicisPcRef = useRef<InicisPcOnceRef>(null);
  const inicisMobileRef = useRef<InicisMobileOnceRef>(null);
  const naverRef = useRef<NaverPayRef>(null);
  const mobiliansRef = useRef<MobiliansOnceRef>(null);
  const popupStore = usePopupStore();
  const userStore = useUserStore();
  // 생구선 카테고리 object 가져오기
  const finalSupportCategory = useFindSupportCategory(SUPPORT_CATEGORY_ONCE_IG);

  useEffect(() => {
    // items가 없으면 튕기기
    if (!items || !items.length) {
      popupStore.show(`선물 정보가 올바르지 않습니다`, {
        onConfirm: () => window.history.back(),
      });
    }
    // 개인정보 암호화키 삭제 (진입)
    resetDonateEncKey();
  }, [items, popupStore]);

  const finalAmount = useMemo(
    () =>
      items
        ? items.reduce(
            (result, item) => result + item.productPrice * item.count,
            0,
          )
        : 0,
    [items],
  );

  const [showTooltip, setShowTooltip] = useState(false);
  // 실명인증 완료 여부
  const [isNameCheckValid, setIsNameCheckValid] = useState<boolean>(false);
  // 실명인증후 넘어오는 사용자정보
  const [memberInfo, setMemberInfo] = useState<NameCheckMemberInfo>();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
    getValues,
    reset,
  } = useForm<GiftFormData>({
    defaultValues: {
      // default는 개인
      supporterType: ENUM_SUPPORTER_TYPE.person,
      memberTypeCode: '',
      // 가변
      memberName: ``,
      birthday: ``,
      mobilePhoneNumber: ``,
      juminNumber: ``,
      contact: ``,
      // step3
      signBinary: ``,
      cardNumber1: ``,
      cardNumber2: ``,
      cardNumber3: ``,
      cardNumber4: ``,
      cardExpireM: ``,
      cardExpireY: ``,
    },
  });
  const {
    allBank: { nodes: banks },
  } = useStaticQuery(graphql`
    query {
      allBank {
        nodes {
          code
          description
        }
      }
    }
  `);

  const businessNumber = watch(`businessNumber`);

  useEffect(() => {
    // 사용자 정보가 로딩되면 (로그인 상태면) 기본값 세팅
    if (userStore.user) {
      const phoneNumber =
        userStore.user.mobilePhoneNumber &&
        `${userStore.user.mobilePhoneNumber}`.replace(/-/g, ``);
      const defaultValues: Partial<GiftFormData> = {
        supporterType: [
          ENUM_SUPPORTER_TYPE.group,
          ENUM_SUPPORTER_TYPE.corporation,
        ].includes(userStore.user.donorType)
          ? ENUM_SUPPORTER_TYPE.group
          : ENUM_SUPPORTER_TYPE.person,
        memberName: userStore.user.name,
        email: userStore.user.email,
        mobilePhoneNumber: phoneNumber,
        memberTypeCode: userStore.user.donorTypeCode,
        address: userStore.user.address1,
        addressDetail: userStore.user.address2,
        zipCode: userStore.user.zip,
        mobileAgree: userStore.isSmsAgree(),
        emailAgree: userStore.isEmailAgree(),
        postAgree: userStore.isPostAgree(),
        birthday: userStore.getFullBirthday() || '',
        juminNumber1: ``,
        juminNumber2: ``,
        gender: userStore.user.gender || '',
      };

      // 개인이고, 주민번호 있다면
      if (userStore.isIndividualUser()) {
        if (userStore.user.juminnoLength) {
          defaultValues.juminNumber1 = userStore.user.juminno.substring(0, 6);
          if (userStore.user.juminnoLength === 13) {
            defaultValues.juminNumber2 = userStore.user.juminno.substring(7);
            defaultValues.agreeReceipt = true;
          }
          // 실명인증 완료처리
          if (userStore.isValidJuminno()) {
            setIsNameCheckValid(true);
          } else {
            setIsNameCheckValid(false);
          }
        }
      } else {
        defaultValues.memberTypeCode = userStore.user.donorTypeCode;
        defaultValues.contact = phoneNumber;
        defaultValues.managerEmail = userStore.user.email;
        defaultValues.businessNumber = userStore.user.businessNumber; // 사업자번호
      }

      // 기본값 덮어쓰기
      reset(defaultValues);
    }
  }, [reset, userStore, userStore.user]);

  const {
    supportCategory,
    // supporterType,
    supportTerm,
    // memberTypeCode,
  } = FixedData;
  //
  const supporterType = watch(`supporterType`);
  const memberTypeCode = watch(`memberTypeCode`);
  const agreeReceipt = watch(`agreeReceipt`);
  const postAgree = watch(`postAgree`);

  const birthdayInput = watch(`birthday`);
  // 생일을 dayjs 인스턴스로 변환
  const birthday = useMemo(
    () =>
      birthdayInput?.length === 8 &&
      dayjs(birthdayInput, `YYYYMMDD`, true).isValid()
        ? dayjs(birthdayInput, `YYYYMMDD`, true)
        : null,
    [birthdayInput],
  );
  const age = useMemo(() => birthday && dayjs().diff(birthday, `year`, true), [
    birthday,
  ]);
  // 14세 미만 여부
  const isUnderFourteen = age !== null && age < 14 && age >= 0;

  // Step3 데이터
  // 본인인증 통과여부
  const [isValidRelationPhone, setIsValidRelationPhone] = useState(false);

  // 불러온 후원자 정보가 있는지 여부
  const isDonor = useMemo(() => userStore.isDonor(), [userStore]);

  // 현금영수증 신청 여부
  const isRequestReceipt = (param: any) => {
    if (
      param?.supporterType === ENUM_SUPPORTER_TYPE.person &&
      param?.agreeReceipt === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  // 진입시 step1 이벤트 발생
  useEffect(() => {
    if (finalAmount && finalSupportCategory) {
      // gtm beginCheckout
      GtmHelper.beginCheckout({
        campaignName: 'InspiredGift',
        campaignPagenum: 'D302',
        promotionName: 'InspiredGift',
        promotionId: 'D302',
        value: finalAmount.toString(),
        items: items?.map((item) => ({
          itemId: item.productCode,
          itemName: item.productName,
          itemCategory: SUPPORT_TERM_ONCE,
          itemCategory2: '',
          itemCategory3: '',
          itemListName: item.categoryCodeName,
          price: item.productPrice.toString(),
          quantity: item.count.toString(),
        })),
      });
    }
  }, [finalAmount, finalSupportCategory, items]);

  // 결제
  const onSubmit = useCallback(
    async (formData: GiftFormData) => {
      // 상품이 있어야함
      if (!items) {
        return;
      }
      // 고정데이터와 폼데이터 병합
      const finalData = { ...formData, ...FixedData };

      const productName = `일시 - ${
        items.length > 1
          ? `${items[0].productName} 외 ${items.length - 1}`
          : items[0].productName
      }`;

      const encKey = getDonateEncKey();
      const finalParams: SubmitParams = {
        // 상품정보
        productCode: items.map((item) => item.productCode).join(`,`),
        // 수량은 단일상품 결제때만 넘기고, 나머지 경우는 장바구니에 담겨있으므로 보내지 않음
        productCount: items.length === 1 ? items[0].count : undefined,
        // Step1
        supportTypeCode:
          finalData.supportTerm === SUPPORT_TERM_ONCE
            ? SUPPORT_TERM_ONCE
            : SUPPORT_TERM_REGULAR,
        donationCode: finalData.supportCategory || ``,
        totalAmount: finalAmount,

        // Step2
        memberTypeCode:
          finalData.supporterType === ENUM_SUPPORTER_TYPE.person
            ? MEMBER_TYPE_INDIVIDUAL
            : finalData.memberTypeCode || ``,
        memberName: finalData.memberName,
        juminNumber:
          finalData.supporterType === ENUM_SUPPORTER_TYPE.person
            ? finalData.agreeReceipt === true
              ? // 240627 - 보안점검 : 주민등록번호 평문 > 암호화 데이터로 교체
                finalData.juminEnc || ''
              : ''
            : finalData.businessNumber || '',
        birthday: finalData.birthday,
        agreeReceiptYn: finalData.agreeReceipt ? `Y` : `N`,
        mobilePhoneNumber:
          finalData.supporterType === ENUM_SUPPORTER_TYPE.person
            ? finalData.mobilePhoneNumber
            : finalData.contact,
        email:
          finalData.supporterType === ENUM_SUPPORTER_TYPE.person
            ? finalData.email
            : finalData.managerEmail,
        businessNumber: finalData.businessNumber,
        managerName: finalData.managerName,
        managerEmail: finalData.managerEmail,
        mobileAgreeYn: finalData.mobileAgree ? `Y` : `N`,
        emailAgreeYn: finalData.emailAgree ? `Y` : `N`,
        postAgreeYn: finalData.postAgree ? `Y` : `N`,
        zipCode: finalData.zipCode || ``,
        address: finalData.address || ``,
        addressDetail: finalData.addressDetail,
        relationName: finalData.relationName,
        relationTelNumber: finalData.relationTelNumber,

        // Step3
        paymentTypeCode: finalData.paymentType,

        // etc
        productName,
        deviceType: isMobileDevice() ? `MOBILE` : `PC`,
        nationCode: `KR`,
        pathType: `IG`,
        moduleCode: `EMPTY`,
        successUrl: `${process.env.SELF_URL}/involve/individual/ig/form/complete`,
        // trackcode, solicitcode 있으면 전송
        trackcode: SessionStorage.getItem(`trackcode`) || ``,
        trk_id: SessionStorage.getItem(`trk_id`) || ``,
        solicitCode: SessionStorage.getItem(`solicitcode`) || ``,

        // 기업회원 누락으로 추가
        contact: finalData.contact,

        // 개인정보 암호화
        encCert: isRequestReceipt(finalData) === true && encKey?.cert,
        publicKey: isRequestReceipt(finalData) === true && encKey?.key,
        /*
        isSecureContext:
          isRequestReceipt(finalData) === true && encKey?.isSecureContext,
        */
        secureContextYn:
          isRequestReceipt(finalData) === true && encKey?.isSecureContext
            ? 'Y'
            : 'N',
      };

      const orderData = {
        campaignName: 'InspiredGift',
        campaignPagenum: 'D302',
        promotionName: 'InspiredGift',
        promotionId: 'D302',
        value: finalAmount.toString(),
        items: items?.map((item) => ({
          itemId: item.productCode,
          itemName: item.productName,
          itemCategory: SUPPORT_TERM_ONCE,
          itemCategory2:
            finalData.birthday &&
            dayjs(finalData.birthday, `YYYYMMDD`, true).isValid()
              ? `${finalData.birthday.slice(0, 4)}`
              : '',
          itemCategory3: finalData.gender || '',
          itemListName: item.categoryCodeName,
          itemBrand: '생명을 구하는 선물',
          price: item.productPrice.toString(),
          quantity: item.count.toString(),
        })),
        paymentType: finalParams?.paymentTypeCode,
      };

      // 생명을 구하는 선물 add_donor_info, add_payment_info 동시에 전송
      // gtm addDonorInfo
      GtmHelper.addDonorInfo(orderData);
      // gtm addPaymentInfo
      GtmHelper.addPaymentInfo(orderData);

      // 전송 먼저
      const {
        resultCode,
        resultMessage,
        orderNumber,
        mkey,
        signature,
        timestamp,
        reserveId,
        orderToken,
      } = await PaymentControllerService.paymentInfoSetUsingPost(finalParams);

      // 주문번호 생성에 성공하면
      if (resultCode === `success`) {
        // 주문정보 저장
        SessionStorage.setItem(`orderData`, JSON.stringify(orderData));
        // 주문토큰 저장
        SessionStorage.setItem(`orderToken`, orderToken);
        // trackcode, trk_id, solicitcode 비우기
        SessionStorage.removeItem(`trackcode`);
        SessionStorage.removeItem(`trk_id`);
        SessionStorage.removeItem(`solicitcode`);
        // 개인정보 암호화키 삭제 (완료)
        resetDonateEncKey();
        // 이니시스
        if (
          [PAYTYPE_ONCE_CARD, PAYTYPE_ONCE_BANKING].includes(
            finalData.paymentType,
          )
        ) {
          // 모바일이면
          if (isMobileDevice()) {
            if (!orderNumber) {
              console.error(`주요정보가 넘어오지 않았습니다`);
              return;
            }
            inicisMobileRef.current?.init({
              payMethod: INICIS_PAY_METHOD_MOBILE_MAP[finalData.paymentType],
              goods: finalParams.productName,
              uname: finalParams.memberName,
              amt: finalParams.totalAmount,
              oid: orderNumber,
            });
          } else {
            if (!orderNumber || !mkey || !signature || !timestamp) {
              console.error(`주요정보가 넘어오지 않았습니다`);
              return;
            }
            inicisPcRef.current?.init({
              payMethod: INICIS_PAY_METHOD_PC_MAP[finalData.paymentType],
              goodname: finalParams.productName,
              buyername: finalParams.memberName,
              buyeremail: finalParams.email,
              buyertel: finalParams.mobilePhoneNumber,
              price: finalParams.totalAmount,
              mKey: mkey,
              oid: orderNumber,
              signature,
              timestamp,
            });
          }
        }
        // 네이버 결제
        else if (finalData.paymentType === PAYTYPE_ONCE_NAVERPAY) {
          if (!orderNumber) {
            console.error(`주요정보가 넘어오지 않았습니다`);
            return;
          }
          naverRef.current?.payNormal({
            productCount: 1,
            productName: finalParams.productName,
            totalPayAmount: finalParams.totalAmount,
            taxScopeAmount: 0,
            taxExScopeAmount: finalParams.totalAmount,
            merchantPayKey: orderNumber,
            merchantUserKey: finalParams.memberName.replaceAll(/\s/g, '_'),
          });
        }
        // 모빌리언스 휴대폰 결제
        // else if (finalData.paymentType === PAYTYPE_ONCE_PHONE) {
        //   mobiliansRef.current?.init({
        //     goodname: finalParams.productName,
        //     price: finalParams.totalAmount,
        //     oid: orderNumber,
        //   });
        // }
      }
      // 주문번호 생성에 실패하면
      else if (resultMessage) {
        popupStore.show(resultMessage);
      }
    },
    [finalAmount, items, popupStore],
  );

  // 우편 신청시 기본값 세팅
  useEffect(() => {
    if (postAgree && memberInfo) {
      setValue(`zipCode`, memberInfo.zipCode);
      setValue(`address`, memberInfo.address);
      setValue(`addressDetail`, memberInfo.addressDetail);
    }
  }, [memberInfo, postAgree, setValue]);

  // 실명인증후 회원정보 넘어온것 세팅
  useEffect(() => {
    if (memberInfo) {
      setValue(`email`, memberInfo.email);
      setValue(
        `mobilePhoneNumber`,
        memberInfo.mobilePhoneNumber &&
          `${memberInfo.mobilePhoneNumber}`.replaceAll(`-`, ``),
      );
      setValue(`mobileAgree`, memberInfo.mailSms === `Y`);
      setValue(`emailAgree`, memberInfo.mailEdm === `Y`);
      setValue(`postAgree`, memberInfo.mailDm === `Y`);
      setValue(`birthday`, userStore.getFullBirthday());
    }
  }, [getValues, memberInfo, setValue]);

  const getByteLength = (s, b = 0, i = 0, c = 0) => {
    for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 2 : 1);
    return b;
  };

  //후원 진입시 내가 후원할 선물로 이동
  const isInitialLoad = useRef(true); // 최초 진입 여부
  const initFocus = () => {
    // ✅ 최초 진입일 때만 실행
    if (isInitialLoad.current) {
      // 최초 진입 이후엔 false로 바꿔서 이후 스크롤 안 되게
      isInitialLoad.current = false;

      // ref 사용시 유동적 계산 단계가 더 많아 고정값 입력
      let sectionTop: number = 0;
      const width = window.innerWidth;
      if (Breakpoints['mobile'] >= width) {
        sectionTop = 230;
      } else if (
        Breakpoints['mobile'] < width &&
        Breakpoints['tablet'] >= width
      ) {
        sectionTop = 230;
      } else {
        sectionTop = 300;
      }
      if (sectionTop > 0) {
        window.scrollTo({
          top: sectionTop,
          behavior: 'auto',
        });
      }
    }
  };

  // 포커스 이동
  useEffect(() => {
    initFocus();
  }, []);

  return (
    <LayoutWithTitle location={location} title="후원신청서 작성">
      <GiftFormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Section className="sec1">
            {items && <GiftList items={items} title="내가 후원할 선물" />}
          </Section>

          <Section className="sec2">
            <ToggleBox>
              <StepHead>
                <h3>
                  <Tit size="s2">후원자 정보</Tit>
                </h3>
              </StepHead>
              <StepBody>
                <HR
                  color="#1cabe2"
                  css={`
                    margin-bottom: 24px;
                  `}
                />

                {/* 후원자 유형 선택 박스 추가 */}
                <FormGroup
                  noMargin
                  style={{
                    paddingBottom: '24px',
                    borderBottom: '1px solid rgb(229, 230, 232)',
                  }}
                >
                  <h4 style={{ fontSize: '20px', paddingBottom: '20px' }}>
                    후원자 유형
                  </h4>
                  <Row>
                    <InputRadioGroup full>
                      <InputRadio
                        name="supporterType"
                        ref={register}
                        tab
                        value={ENUM_SUPPORTER_TYPE.person}
                        label="개인"
                        defaultChecked
                        // 법인/단체에서 다시 개인 누를때 실명인증 초기화
                        onClick={() => setIsNameCheckValid(false)}
                        readOnly={isDonor}
                      />
                      <InputRadio
                        name="supporterType"
                        ref={register}
                        tab
                        value={ENUM_SUPPORTER_TYPE.group}
                        label="법인/단체"
                        readOnly={isDonor}
                      />
                    </InputRadioGroup>
                  </Row>
                  {supporterType === ENUM_SUPPORTER_TYPE.group ? (
                    <>
                      <Row>
                        <Select
                          name="memberTypeCode"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          readOnly={isDonor}
                        >
                          <option value="">종류 선택</option>
                          {/* 법인 옵션 */}
                          {Object.entries(
                            MEMBER_TYPES[ENUM_SUPPORTER_TYPE.corporation],
                          ).map(([value, label]) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                          {/* 단체 옵션 */}
                          {Object.entries(
                            MEMBER_TYPES[ENUM_SUPPORTER_TYPE.group],
                          ).map(([value, label]) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Select>
                      </Row>
                      {errors.memberTypeCode && (
                        <ErrorMessage>
                          {errors.memberTypeCode.message}
                        </ErrorMessage>
                      )}
                    </>
                  ) : null}
                </FormGroup>

                {/* 후원자 유형 : 개인 */}
                {supporterType === ENUM_SUPPORTER_TYPE.person && (
                  <>
                    <FormItem noBorder style={{ paddingTop: '24px' }}>
                      <H4>후원자 정보</H4>
                      <FormGroup className="FormGroup">
                        <LabelText require>성명</LabelText>
                        <InputText
                          placeholder="성명 입력"
                          name="memberName"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          readOnly={isNameCheckValid || isDonor}
                        />
                        {errors.memberName && (
                          <ErrorMessage>
                            {errors.memberName.message}
                          </ErrorMessage>
                        )}
                      </FormGroup>
                      <FormGroup className="FormGroup">
                        <Row>
                          <LabelText>기부금 영수증</LabelText>
                        </Row>
                        <Row
                          css={`
                            margin: 8px 0 0 0;
                          `}
                        >
                          <InputCheckbox
                            label="신청"
                            name="agreeReceipt"
                            ref={register}
                            readOnly={isNameCheckValid}
                          />
                        </Row>
                        {agreeReceipt && (
                          <>
                            <Row>
                              <Col desktop="auto">
                                <input
                                  type="hidden"
                                  name="gender"
                                  ref={register}
                                />
                                <input
                                  type="hidden"
                                  name="juminEnc"
                                  ref={register}
                                />
                                <FormatJumin
                                  style={
                                    isNameCheckValid
                                      ? { background: '#f8f9fa' }
                                      : {}
                                  }
                                >
                                  <Controller
                                    control={control}
                                    name="juminNumber1"
                                    rules={
                                      (agreeReceipt && {
                                        required: {
                                          value: true,
                                          message: `필수 입력입니다`,
                                        },
                                        validate: (value) =>
                                          dayjs(
                                            value,
                                            `YYMMDD`,
                                            true,
                                          ).isValid() ||
                                          `주민등록번호 자릿수를 확인하세요.`,
                                      }) ||
                                      undefined
                                    }
                                    as={
                                      <InputNumber
                                        format="######"
                                        placeholder="주민번호 앞자리"
                                        readOnly={isNameCheckValid}
                                        className="juminNumber"
                                        autoComplete="off"
                                        style={{ border: '0px none' }}
                                      />
                                    }
                                  />
                                  <span className="hypen">-</span>
                                  <Controller
                                    control={control}
                                    name="juminNumber2"
                                    rules={
                                      (agreeReceipt && {
                                        required: {
                                          value: true,
                                          message: `필수 입력입니다`,
                                        },
                                        validate: (value) => {
                                          if (value.length !== 7) {
                                            return `주민등록번호 자릿수를 확인하세요.`;
                                          }
                                          if (value && !isNameCheckValid) {
                                            return `실명인증이 필요합니다`;
                                          }
                                          return;
                                        },
                                      }) ||
                                      undefined
                                    }
                                    as={
                                      isNameCheckValid ? (
                                        <InputText
                                          format="#######"
                                          placeholder="주민번호 뒷자리"
                                          readOnly={isNameCheckValid}
                                          className="juminNumber"
                                          autoComplete="off"
                                          style={{ border: '0px none' }}
                                          type="password"
                                        />
                                      ) : (
                                        <InputNumberMasking
                                          format="#######"
                                          placeholder="주민번호 뒷자리"
                                          readOnly={isNameCheckValid}
                                          className="juminNumber"
                                          autoComplete="off"
                                          style={{ border: '0px none' }}
                                        />
                                      )
                                    }
                                  />
                                </FormatJumin>
                              </Col>
                              <RightCol>
                                {isNameCheckValid ? (
                                  <FormButton color="grey" disabled>
                                    인증완료
                                  </FormButton>
                                ) : (
                                  <SirenNameCheck
                                    getParams={() => ({
                                      name: getValues(`memberName`),
                                      jumin:
                                        getValues(`juminNumber1`) +
                                        '' +
                                        getValues(`juminNumber2`),
                                      isNameCheckValid:
                                        userStore.isIndividualUser() &&
                                        userStore.isValidJuminno(),
                                    })}
                                    onSuccess={(result) => {
                                      // 주민등록번호 잘라서 생년월일 세팅
                                      setValue(
                                        `birthday`,
                                        getFullBirthday(
                                          getValues(`juminNumber1`) +
                                            getValues(`juminNumber2`),
                                        ),
                                      );
                                      setValue(
                                        `gender`,
                                        result.certInfo?.gender,
                                      );
                                      setValue(
                                        `juminEnc`,
                                        result.certInfo?.juminEnc,
                                      );
                                      setIsNameCheckValid(true);
                                      clearErrors(`gender`);
                                      clearErrors(`juminEnc`);
                                      // 실명인증 성공시 비로그인 상태면 최근 후원정보 가져오기
                                      if (!userStore.isLoggedIn()) {
                                        // 정기후원정보가 넘어오면 최근 후원정보 가져올지 확인
                                        // 2021-09-09 1651610223 비로그인+최근후원정보 불러오기 기능 비활성 요청에 의해 처리
                                        //   if (
                                        //     result.payInfo.paymentType &&
                                        //     result.memberInfo.email
                                        //   ) {
                                        //     popupStore.show(
                                        //       `최근 후원정보를 사용하시겠습니까?`,
                                        //       {
                                        //         onConfirm: (ok) => {
                                        //           if (ok) {
                                        //             setMemberInfo(
                                        //               result.memberInfo,
                                        //             );
                                        //           }
                                        //         },
                                        //         showCancelButton: true,
                                        //       },
                                        //     );
                                        //   }
                                      }
                                    }}
                                    onError={() => {
                                      popupStore.show(
                                        `실명인증에 실패하였습니다`,
                                      );
                                    }}
                                    renderSubmitButton={(buttonProps) => (
                                      <FormButton outline {...buttonProps}>
                                        실명인증
                                      </FormButton>
                                    )}
                                  />
                                )}
                              </RightCol>
                            </Row>
                            {(errors.juminNumber1 || errors.juminNumber2) && (
                              <ErrorMessage>
                                {errors.juminNumber1?.message ||
                                  errors.juminNumber2?.message}
                              </ErrorMessage>
                            )}
                            <Row>
                              <FormTextTip>
                                입력하신 주민등록번호는{` `}
                                <strong>국세청 기부금 영수증 발급 대행</strong>
                                을 위해 사용됩니다.
                              </FormTextTip>
                            </Row>
                          </>
                        )}
                      </FormGroup>
                      {/* 로그인정보중 생년월일이 있거나, 실명인증시 hidden */}
                      <ToggleDiv
                        active={
                          (!userStore.getFullBirthday() ||
                            userStore.getFullBirthday()?.length !== 8) &&
                          !isNameCheckValid
                        }
                      >
                        <FormGroup noMargin>
                          <LabelText require>생년월일</LabelText>
                          <Row>
                            <Controller
                              control={control}
                              name="birthday"
                              rules={{
                                required: {
                                  value: true,
                                  message: `필수 입력입니다`,
                                },
                                validate: (value) =>
                                  dayjs(value, `YYYYMMDD`, true).isValid() ||
                                  `올바르지 않은 형식입니다 (8자리입력. YYYYMMDD)`,
                              }}
                              as={
                                <InputNumber
                                  format="########"
                                  placeholder="생년월일 8자리 입력(YYYYMMDD)"
                                />
                              }
                            />
                          </Row>
                          {errors.birthday && (
                            <ErrorMessage>
                              {errors.birthday.message}
                            </ErrorMessage>
                          )}
                          <Row>
                            <FormTextTip>
                              만 14세 미만의 경우, 법정대리인 인증이 완료되어야
                              후원이 가능합니다.
                            </FormTextTip>
                          </Row>
                        </FormGroup>
                      </ToggleDiv>
                    </FormItem>
                    {/* 14세 미만일때 법정대리인 정보 입력 */}
                    {isUnderFourteen && (
                      <DonateFormItem
                        bgGray
                        noBorder
                        css={`
                          padding-top: 40px;
                          margin-bottom: 40px;
                        `}
                      >
                        <H4>법정대리인 정보</H4>
                        <FormGroup className="FormGroup">
                          <LabelText require>법정대리인 성명</LabelText>
                          <InputText
                            name="relationName"
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            })}
                            placeholder="성명 입력"
                            readOnly={isValidRelationPhone}
                          />
                          {errors.relationName && (
                            <ErrorMessage>
                              {errors.relationName.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                        <FormGroup className="FormGroup">
                          <LabelText require>후원자와의 관계</LabelText>
                          <Select
                            name="relationType"
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            })}
                          >
                            <option value="">관계 선택</option>
                            <option>부모</option>
                            <option>친척</option>
                            <option>지인</option>
                            <option>선생님</option>
                            <option>기타</option>
                          </Select>
                          {errors.relationType && (
                            <ErrorMessage>
                              {errors.relationType.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                        <FormGroup noMargin className="FormGroup">
                          <LabelText require>법정대리인 휴대폰번호</LabelText>
                          <Row className="certify">
                            <div className="col-left">
                              {isValidRelationPhone ? (
                                <FormButton outline color="grey" disabled>
                                  인증완료
                                </FormButton>
                              ) : (
                                <NiceAuthenticationForm
                                  onSuccess={(result) => {
                                    setIsValidRelationPhone(true);
                                    setValue(
                                      `relationTelNumber`,
                                      result.mobile,
                                    );
                                    clearErrors(`relationTelNumber`);
                                  }}
                                  renderSubmitButton={(props) => (
                                    <FormButton outline {...props}>
                                      본인인증
                                    </FormButton>
                                  )}
                                />
                              )}
                            </div>
                            <div className="col-right">
                              <InputText
                                name="relationTelNumber"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: `필수 입력입니다`,
                                  },
                                })}
                                placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                                readOnly
                              />
                            </div>
                          </Row>
                          {errors.relationTelNumber && (
                            <ErrorMessage>
                              {errors.relationTelNumber.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                      </DonateFormItem>
                    )}
                    <DonateFormItem className="FormItem">
                      <FormGroup className="FormGroup">
                        <LabelText require>휴대폰번호</LabelText>
                        <Controller
                          control={control}
                          name="mobilePhoneNumber"
                          rules={{
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          }}
                          as={
                            <InputNumber
                              format="###########"
                              placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                            />
                          }
                        />
                        {errors.mobilePhoneNumber && (
                          <ErrorMessage>
                            {errors.mobilePhoneNumber.message}
                          </ErrorMessage>
                        )}
                      </FormGroup>
                      <FormGroup className="FormGroup">
                        <LabelText require>이메일</LabelText>
                        <InputText
                          name="email"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                            pattern: {
                              value: emailRegex,
                              message: `올바르지 않은 이메일주소 입니다`,
                            },
                          })}
                          placeholder="이메일주소 입력"
                        />
                        {errors.email && (
                          <ErrorMessage>{errors.email.message}</ErrorMessage>
                        )}
                      </FormGroup>
                      {/* 후원자 유형이 개인이고 후원분야가 기념기부인 경우 */}
                      {supporterType === ENUM_SUPPORTER_TYPE.person &&
                        supportCategory === SUPPORT_CATEGORY_ONCE_MEMORY && (
                          <>
                            <HR
                              css={`
                                margin: 40px 0 24px 0;
                              `}
                            />
                            <FormGroup>
                              <H4>기념일 정보</H4>
                              <LabelText require>기념일 종류</LabelText>
                              <Select
                                name="anniType"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: `필수 입력입니다`,
                                  },
                                })}
                              >
                                <option value="">기념일 종류 선택</option>
                                <option value="출생">출생</option>
                                <option value="백일">백일</option>
                                <option value="첫돌">첫돌</option>
                                <option value="생일">생일</option>
                                <option value="수상">수상</option>
                                <option value="승진">승진</option>
                                <option value="회갑">회갑</option>
                                <option value="결혼">결혼</option>
                                <option value="연애">연애</option>
                                <option value="추모">추모</option>
                                <option value="기타">기타</option>
                              </Select>
                              {errors.anniType && (
                                <ErrorMessage>
                                  {errors.anniType.message}
                                </ErrorMessage>
                              )}
                            </FormGroup>
                            <FormGroup className="t2">
                              <LabelText require>기념일</LabelText>
                              <InputDate
                                name="anniDate"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: `필수 입력입니다`,
                                  },
                                })}
                              />
                              {errors.anniDate && (
                                <ErrorMessage>
                                  {errors.anniDate.message}
                                </ErrorMessage>
                              )}
                            </FormGroup>
                            <FormGroup className="t2">
                              <LabelText require>사연</LabelText>
                              <InputTextarea
                                placeholder="기념일 사연을 입력해주세요."
                                name="story"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: `필수 입력입니다`,
                                  },
                                })}
                              />
                              {errors.story && (
                                <ErrorMessage>
                                  {errors.story.message}
                                </ErrorMessage>
                              )}
                            </FormGroup>
                          </>
                        )}
                    </DonateFormItem>
                  </>
                )}

                {/* 후원자 유형 : 법인/단체 */}
                {supporterType === ENUM_SUPPORTER_TYPE.group && (
                  <DonateFormItem noBorder>
                    <H4>후원자 정보</H4>
                    {/* 법인의 경우 */}
                    {Object.keys(
                      MEMBER_TYPES[ENUM_SUPPORTER_TYPE.corporation],
                    ).includes(memberTypeCode) ? (
                      <>
                        <FormGroup>
                          <LabelText require>법인명</LabelText>
                          <InputText
                            placeholder="법인명 입력"
                            name="memberName"
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                              validate: {
                                isValidRange: (value) => {
                                  if (getByteLength(value) > 30)
                                    return `제한 길이가 초과되었습니다.(최대 한글 15자)`;
                                },
                              },
                            })}
                            readOnly={isDonor}
                          />
                          {errors.memberName && (
                            <ErrorMessage>
                              {errors.memberName.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <LabelText require>사업자번호</LabelText>
                          <Controller
                            control={control}
                            name="businessNumber"
                            rules={{
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            }}
                            as={
                              <InputNumber
                                format="##########"
                                placeholder="사업자번호 입력(&lsquo;-&rsquo; 제외)"
                                readOnly={isDonor && !!businessNumber}
                              />
                            }
                          />
                          {errors.businessNumber && (
                            <ErrorMessage>
                              {errors.businessNumber.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                      </>
                    ) : (
                      <FormGroup>
                        <LabelText require>단체명</LabelText>
                        <InputText
                          placeholder="단체명 입력"
                          name="memberName"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                            validate: {
                              isValidRange: (value) => {
                                if (getByteLength(value) > 30)
                                  return `제한 길이가 초과되었습니다.(최대 한글 15자)`;
                              },
                            },
                          })}
                          readOnly={isDonor}
                        />
                        {errors.memberName && (
                          <ErrorMessage>
                            {errors.memberName.message}
                          </ErrorMessage>
                        )}
                      </FormGroup>
                    )}
                    <FormGroup>
                      <LabelText require>연락처</LabelText>
                      <Controller
                        control={control}
                        name="contact"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        }}
                        as={
                          <InputNumber
                            format="###########"
                            placeholder="연락처 입력(&lsquo;-&rsquo; 제외)"
                          />
                        }
                      />
                      {errors.contact && (
                        <ErrorMessage>{errors.contact.message}</ErrorMessage>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <LabelText require>담당자명</LabelText>
                      <InputText
                        name="managerName"
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        placeholder="담당자명 입력"
                      />
                      {errors.managerName && (
                        <ErrorMessage>
                          {errors.managerName.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <LabelText require>담당자 이메일</LabelText>
                      <InputText
                        name="managerEmail"
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                          pattern: {
                            value: emailRegex,
                            message: `올바르지 않은 이메일주소 입니다`,
                          },
                        })}
                        placeholder="이메일주소 입력"
                      />
                      {errors.managerEmail && (
                        <ErrorMessage>
                          {errors.managerEmail.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>
                    {/* 단체일때 주소 입력 필수 */}
                    <FormGroup>
                      <LabelText require>주소</LabelText>
                      <Row>
                        <Col desktop="auto">
                          <InputText
                            name="zipCode"
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            })}
                            readOnly
                          />
                        </Col>
                        <RightCol desktop="none">
                          <AddressButton
                            onSelectAddress={(address) => {
                              setValue(`zipCode`, address.zonecode);
                              setValue(`address`, address.address);
                            }}
                            renderButton={(buttonProps) => (
                              <FormButton {...buttonProps}>주소검색</FormButton>
                            )}
                          />
                        </RightCol>
                      </Row>
                      <Row>
                        <InputText name="address" ref={register} readOnly />
                      </Row>
                      <Row>
                        <InputText name="addressDetail" ref={register} />
                      </Row>
                      {errors.zipCode && (
                        <ErrorMessage>{errors.zipCode.message}</ErrorMessage>
                      )}
                    </FormGroup>
                  </DonateFormItem>
                )}

                <FormGroup className="FormGroup">
                  <LabelText>
                    전 세계 어린이를 위한 소식·후원·캠페인 안내 수신동의(선택)
                  </LabelText>
                  <LabelFlex>
                    <li>
                      <InputCheckbox
                        label="모바일"
                        name="mobileAgree"
                        ref={register}
                      />
                    </li>
                    <li>
                      <InputCheckbox
                        label="이메일"
                        name="emailAgree"
                        ref={register}
                      />
                    </li>
                    <li style={{ position: `relative` }}>
                      <CheckboxLabel>
                        <CheckboxInput
                          name="postAgree"
                          ref={register}
                          onClick={(e) =>
                            setShowTooltip(e.currentTarget.checked)
                          }
                        />
                        <CheckboxObject />
                        <CheckboxText>우편</CheckboxText>
                      </CheckboxLabel>
                      {showTooltip && (
                        <PostTooltip
                          onRequestClose={(isDigitalSelected) => {
                            if (isDigitalSelected) {
                              setValue(`postAgree`, false);
                              setValue(`mobileAgree`, true);
                              setValue(`emailAgree`, true);
                            }
                            setShowTooltip(false);
                          }}
                        />
                      )}
                    </li>
                  </LabelFlex>
                </FormGroup>
                <NotiBox className="FormGroup last">
                  <NotiList>
                    <NotiItem>
                      <strong>
                        공지사항, 긴급구호 및 회원관리 안내는 수신 여부와
                        관계없이 발송됩니다.
                      </strong>
                    </NotiItem>
                    <NotiItem>
                      동의 내용은 언제든지 웹사이트 또는 전화상담을 통해 변경이
                      가능합니다.
                    </NotiItem>
                  </NotiList>
                </NotiBox>
                {/* 우편 신청시 주소 입력 */}
                {supporterType === ENUM_SUPPORTER_TYPE.person && postAgree && (
                  <FormGroup className="FormGroup">
                    <LabelText require>주소</LabelText>
                    <Row>
                      <Col desktop="auto">
                        <InputText
                          name="zipCode"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          readOnly
                        />
                      </Col>
                      <RightCol desktop="none">
                        <AddressButton
                          onSelectAddress={(address) => {
                            setValue(`zipCode`, address.zonecode);
                            setValue(`address`, address.address);
                          }}
                          renderButton={(buttonProps) => (
                            <FormButton {...buttonProps}>주소검색</FormButton>
                          )}
                        />
                      </RightCol>
                    </Row>
                    <Row>
                      <InputText name="address" ref={register} readOnly />
                    </Row>
                    <Row>
                      <InputText name="addressDetail" ref={register} />
                    </Row>
                    {errors.zipCode && (
                      <ErrorMessage>{errors.zipCode.message}</ErrorMessage>
                    )}
                  </FormGroup>
                )}
              </StepBody>
            </ToggleBox>
          </Section>

          <Section className="sec3">
            <ToggleBox>
              <StepHead>
                <h3>
                  <Tit size="s2">결제 정보</Tit>
                </h3>
              </StepHead>
              <StepBody
                css={`
                  margin-top: 24px;
                `}
              >
                <FormItem>
                  <FormGroup noMargin>
                    <H4>결제수단</H4>
                    <InputRadioGroup
                      full
                      css={`
                        margin-bottom: 96px;
                      `}
                    >
                      <InputRadio
                        name="paymentType"
                        ref={register}
                        tab
                        value={PAYTYPE_ONCE_CARD}
                        label="신용카드"
                        defaultChecked
                      />
                      {/* <InputRadio
                        name="paymentType"
                        ref={register}
                        tab
                        value={PAYTYPE_ONCE_PHONE}
                        label="휴대폰"
                      /> */}
                      <InputRadio
                        name="paymentType"
                        ref={register}
                        tab
                        value={PAYTYPE_ONCE_BANKING}
                        label="무통장입금"
                      />
                      <InputRadio
                        name="paymentType"
                        ref={register}
                        tab
                        value={PAYTYPE_ONCE_NAVERPAY}
                        label="네이버페이"
                      />
                    </InputRadioGroup>
                  </FormGroup>
                </FormItem>
                <StepHead>
                  <h3>
                    <Tit size="s2">약관 및 개인정보 수집·이용 동의</Tit>
                  </h3>
                </StepHead>
                <Agreement
                  allChk
                  name="policyAgree"
                  ref={register({
                    required: {
                      value: true,
                      message: `이용약관에 동의해주세요`,
                    },
                  })}
                  policies={[
                    {
                      required: true,
                      label: `후원 약관 동의`,
                      content: SupportAgreeContent,
                    },
                    {
                      required: true,
                      label: `개인정보 수집·이용 동의`,
                      content: SupportPrivacyAgreeContent,
                    },
                  ]}
                />
                {errors.policyAgree && (
                  <ErrorMessage>{errors.policyAgree.message}</ErrorMessage>
                )}
                <TotalAmount>
                  <dt>총 후원금액</dt>
                  <dd>
                    {finalAmount.toLocaleString()} 원({items?.length || 0}개)
                  </dd>
                </TotalAmount>
                <div className="btn-wrap">
                  <Button
                    type="submit"
                    color="yellow"
                    full
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? `진행 중입니다. 잠시 기다려 주세요`
                      : `후원하기`}
                  </Button>
                </div>
              </StepBody>
            </ToggleBox>
          </Section>
        </form>
      </GiftFormContainer>
      <InicisPcOnceForm ref={inicisPcRef} />
      <InicisMobileOnceForm ref={inicisMobileRef} />
      <NaverPayForm ref={naverRef} />
      <MobiliansOnceForm ref={mobiliansRef} />
    </LayoutWithTitle>
  );
});

export default GifFormPage;
